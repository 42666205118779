import request from '@/utils/request'


export function query(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_wiki/resolvent/query',
        method: 'post',
        data: data
    })
}


export function create(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_wiki/resolvent/create',
        method: 'post',
        data: data
    })
}


export function resolvent_delete(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_wiki/resolvent/del',
        method: 'post',
        data: data
    })
}