<template>
    <div>
        <!-- 添加异常组件弹窗 -->
        <el-dialog
            title="添加该异常的解决方法"
            :visible.sync="dialogFormVisible"
        >
            <el-form :model="formData">
                <el-form-item label="可能问题" :label-width="'120px'">
                    <el-input
                        v-model="formData.possible_question"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>

                <el-form-item label="解决方法" :label-width="'120px'">
                    <el-input
                        v-model="formData.resolvent"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addResolvent">确认</el-button>
            </div>
        </el-dialog>

        <!-- 编辑异常组件弹窗 -->
        <el-dialog title="编辑该异常信息" :visible.sync="dialogEditFormVisible">
            <el-form :model="editData">
                <el-form-item label="问题id" :label-width="'120px'">
                    <el-input
                        v-model="infoData.id"
                        autocomplete="off"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常信息" :label-width="'120px'">
                    <el-input
                        v-model="editData.error_info"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="异常等级" :label-width="'120px'">
                    <el-input
                        v-model.number="editData.error_level"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="是否生成工单" :label-width="'120px'">
                    <el-select v-model="editData.manual" placeholder="请选择">
                        <el-option label="触发" :value="true"></el-option>
                        <el-option label="不触发" :value="false"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editAbnormal">确认</el-button>
            </div>
        </el-dialog>

        <!-- 页头 -->
        <div class="commonInputTopBox">
            <infoHeader
                :content_name="
                    infoData.product_name +
                    ' / ' +
                    infoData.module_name +
                    ' / ' +
                    ' ErrorCode: ' +
                    infoData.error_code_snapshot
                "
            />
            <div style="float: right; margin-top: -65px">
                <el-button type="primary" @click="dialogFormVisible = true"
                    >添加解决方法</el-button
                >
                <el-button type="info" @click="dialogEditFormVisible = true"
                    >编辑该错误异常</el-button
                >
                <el-button type="danger" @click="deleteAbnormal()"
                    >删除该错误异常</el-button
                >
            </div>
        </div>

        <!-- 内容 -->

        <el-divider content-position="left"
            ><span style="font-size: 18px; font-weight: 600"
                >错误码信息</span
            ></el-divider
        >

        <el-descriptions
            title=""
            direction="vertical"
            border
            :column="4"
            style="margin-top: 30px; margin-bottom: 60px"
        >
            <el-descriptions-item label="错误码(完整系统快照)">{{
                infoData.error_code_snapshot
            }}</el-descriptions-item>
            <el-descriptions-item label="模块ID">{{
                infoData.error_code
            }}</el-descriptions-item>
            <el-descriptions-item label="严重等级">{{
                infoData.error_level
            }}</el-descriptions-item>
            <el-descriptions-item label="是否生成工单">{{
                infoData.manual
            }}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left"
            ><span style="font-size: 18px; font-weight: 600"
                >错误码说明</span
            ></el-divider
        >

        <!-- 内容 -->
        <el-descriptions
            title=""
            direction="vertical"
            border
            :column="2"
            style="margin-top: 30px; margin-bottom: 60px"
        >
            <el-descriptions-item label="错误信息">{{
                infoData.error_info
            }}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left"
            ><span style="font-size: 18px; font-weight: 600"
                >解决方法</span
            ></el-divider
        >

        <!-- 内容 -->
        <el-row :gutter="20">
            <el-col
                :span="24"
                v-for="data in tableData"
                :key="data.id"
                style="margin-bottom: 30px"
            >
                <el-card
                    :body-style="{
                        padding: '30px 30px 0px 30px',
                        cursor: 'pointer'
                    }"
                    shadow="hover"
                >
                    <div slot="header" class="clearfix">
                        <span
                            style="
                                font-size: 18px;
                                font-weight: 600;
                                margin-left: 10px;
                            "
                        >
                            可能的错误: {{ data.possible_question }}
                        </span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            type="text"
                            @click="deleteResolvent(data)"
                            >删除这篇文章</el-button
                        >
                    </div>
                    <div style="margin-bottom: 20px">
                        <div style="font-size: 14px; font-weight: 400">
                            可参考解决方法: <br /><br />
                            {{ data.resolvent }}
                        </div>
                    </div>
                    <div style="margin-bottom: 20px">
                        <div
                            style="
                                font-size: 14px;
                                font-weight: 400;
                                color: #c1c1c1;
                            "
                        >
                            <div>
                                编辑者: {{ data.author }} - 发布时间:
                                {{ data.create_time }}
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {
    query,
    create,
    resolvent_delete
} from '@/api/manage/operation/software_fault_wiki/software_fault_wiki.js';

import {
    abnormal_del,
    abnormal_update
} from '@/api/manage/operation/software_fault_managent/abnormal.js';

export default {
    name: 'software_fault_detail',
    data() {
        return {
            infoData: this.$route.query,
            formData: { abnormal_code_id: this.$route.query.id },
            tableData: [],
            editData: {},
            dialogFormVisible: false,
            dialogEditFormVisible: false
        };
    },
    created() {
        this.getAbnromal();
    },
    methods: {
        editAbnormal() {
            abnormal_update({ id: this.infoData.id, ...this.editData }).then(
                (res) => {
                    if(res.code == 200){
                        this.$router.go(-1);
                    }else{
                        this.$message.error(res.msg)
                    }
                }
            );
        },
        deleteAbnormal() {
            this.$confirm('确认删除该异常？').then(() => {
                abnormal_del({ id: this.infoData.id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功');
                        this.$router.go(-1);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        getAbnromal() {
            query({ id: this.infoData.id }).then(res => {
                this.tableData = res.data;
            });
        },
        addResolvent() {
            create(this.formData)
                .then(() => {
                    this.$message.success('添加处理方法成功');
                    this.$router.go(0);
                })
                .catch(error => {
                    this.$message.error(error);
                });
            this.dialogFormVisible = false;
        },
        deleteResolvent(row) {
            this.$confirm('确认删除该解决方法？').then(() => {
                resolvent_delete({ id: row.id }).then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(0);
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.commonControl-body {
    el-descriptions {
        margin-top: 20px;
    }
}

.box-card {
    width: 600px;
    margin: 10px 20px;
}

.manage-button-drawer {
    width: 400px;
    height: 50px;
    position: absolute;
    bottom: 5%;
    left: 17%;
}

.equipment-pitch-item {
    width: 98%;
    background-color: #efefef;
    border-radius: 5px;
    padding: 20px 10px 10px 10px;
}

.device-list {
    height: 600px;
    overflow: auto;
}

.device-title {
    font-size: 16px;
    font-weight: 700;
    color: #303133;
    margin-top: 20px;
    margin-left: 22px;
}
</style>
